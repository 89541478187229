import { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { LdsLoadingSpinner } from "@elilillyco/ux-lds-react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers } from '../store/user';
import { fetchOmtMt } from '../store/maintenance/omtMt';
import { setAxiosBaseURL } from '../plugins/axios';
import getCurrentEnv from '../data/utils/getCurrentEnv';
import data from '../data/env.config.json'
// import { roleMapping } from '../data/roleMapping';

const Middleware = ({ children }) => {
  const dispatch = useDispatch();
  const defaultRole = useSelector(({user})=>user.defaultRole);
  const allRoles = useSelector(({user}) => user.AllRole);
  const [firstRender, setFirstRender] = useState(false);
  const location = useLocation();
  const url = data[getCurrentEnv()]['api_url'];
  const getURL = async () => {
    const response = await fetch(
      url,
      {
      method: 'GET', 
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('Token')}`,
        'Content-Type': 'application/json',
      }});
      const data = await response.json();
      sessionStorage.setItem('gateway', data.gateway);
      setAxiosBaseURL(data.gateway);
  }

  useEffect(() => {
    async function fetchURL() {
      if(!sessionStorage.getItem('gateway')) {
        await getURL();
      } 
    }
    fetchURL();
    if(sessionStorage.getItem('gateway') !== undefined && sessionStorage.getItem('gateway') !== null){
      dispatch(fetchUsers());
    }   
  }, [dispatch, location.pathname, sessionStorage.getItem('gateway')]); 

  useEffect(()=>{
    if(sessionStorage.getItem('gateway')){
      dispatch(fetchOmtMt())
    }
  },[defaultRole,location.pathname])

  useEffect(() => {
    if (defaultRole && !firstRender) {
      setFirstRender(true);
      sessionStorage.setItem('role', defaultRole);
    } 
  }, [defaultRole])

  if (!defaultRole) {
    return <LdsLoadingSpinner size={50} animationSpeed={500} style={{zIndex:'3',position:'absolute',marginLeft:'47vW',marginTop:'3rem', height:'20rem'}}/>
  }
  let selectedRole = sessionStorage.getItem('role');
  if (!allRoles) {
    sessionStorage.removeItem('role');
    return <Navigate to="/error" />;
    // console.log('here!!!!!');
  }
  const realtivePath = location.pathname.toLowerCase();
  if (selectedRole === 'omms_admin' && (realtivePath.includes('/tacct') || realtivePath.includes('/list-management-auditing') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/adhoc-history') || realtivePath.includes('/response-matrix') )) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omms_metadata_manager' && (realtivePath.includes('/request-for-approval') || realtivePath.includes('/tacct') || realtivePath.includes('/list-management-auditing') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/adhoc-history') || realtivePath.includes('/response-matrix') || realtivePath.includes('/archive'))) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omms_peer_review_bia' && (realtivePath.includes('/enter-approved-metadata') || realtivePath.includes('/tacct') || realtivePath.includes('/list-management-auditing') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/adhoc-history') || realtivePath.includes('/response-matrix') || realtivePath.includes('/archive'))) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omms_peer_review_campaign' && (realtivePath.includes('/enter-approved-metadata') || realtivePath.includes('/tacct') || realtivePath.includes('/list-management-auditing') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/adhoc-history') || realtivePath.includes('/response-matrix') || realtivePath.includes('/archive'))) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omms_peer_review_channel' && (realtivePath.includes('/enter-approved-metadata') || realtivePath.includes('/tacct') || realtivePath.includes('/list-management-auditing') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/adhoc-history') || realtivePath.includes('/response-matrix') || realtivePath.includes('/archive'))) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omms_read_only' && (realtivePath.includes('/campaign-metadata-management') || realtivePath.includes('/enter-approved-metadata') || realtivePath.includes('/tacct') || realtivePath.includes('/list-management-auditing') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/adhoc-history') || realtivePath.includes('/response-matrix') || realtivePath.includes('/archive'))) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omms_vendor' && (realtivePath.includes('/enter-approved-metadata') || realtivePath.includes('/campaign-metadata-management/request-for-approval') || realtivePath.includes('/tacct') || realtivePath.includes('/list-management-auditing') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/adhoc-history') || realtivePath.includes('/response-matrix') || realtivePath.includes('/archive'))) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omms_list_management' && (realtivePath.includes('/omms') || realtivePath.includes('/tacct') || realtivePath.includes('/response-matrix') )) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omt_list_management_admin' && (realtivePath.includes('/omms') || realtivePath.includes('/tacct') || realtivePath.includes('/response-matrix') )) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omt_response_matrix' && (realtivePath.includes('/omms') || realtivePath.includes('/tacct') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/list-management-auditing') || realtivePath.includes('/adhoc-history') )) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omt_tacct_bl_metadata_owner' && (realtivePath.includes('/omms') || realtivePath.includes('/enter-approved-metadata') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/list-management-auditing') || realtivePath.includes('/adhoc-history') || realtivePath.includes('/response-matrix') )) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omt_tacct_admin' && (realtivePath.includes('/omms') || realtivePath.includes('/enter-approved-metadata') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/list-management-auditing') || realtivePath.includes('/adhoc-history') || realtivePath.includes('/response-matrix') )) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omt_tacct_bl_metadata_requester' && (realtivePath.includes('/omms') || realtivePath.includes('/edit-category') || realtivePath.includes('/enter-approved-metadata') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/list-management-auditing') || realtivePath.includes('/adhoc-history') || realtivePath.includes('/response-matrix') || realtivePath.includes('/edit-product')  )) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omt_tacct_bl_approver_taxccb' && (realtivePath.includes('/omms') || realtivePath.includes('/edit-category') || realtivePath.includes('/enter-approved-metadata') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/list-management-auditing') || realtivePath.includes('/adhoc-history') || realtivePath.includes('/response-matrix') || realtivePath.includes('/edit-product') )) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omt_tacct_bl_approver_omni' && (realtivePath.includes('/omms') || realtivePath.includes('/edit-category') || realtivePath.includes('/enter-approved-metadata') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/list-management-auditing') || realtivePath.includes('/adhoc-history') || realtivePath.includes('/response-matrix') || realtivePath.includes('/edit-product') )) {
    return <Navigate to='/unauthorized-access' />;
  }
  if (selectedRole === 'omt_tacct_bl_read' && (realtivePath.includes('/omms') || realtivePath.includes('/edit-category') || realtivePath.includes('/enter-approved-metadata') || realtivePath.includes('/edit-product') || realtivePath.includes('/adhoc-list-scrubbing') || realtivePath.includes('/list-management-auditing') || realtivePath === '/tacct/request-approval' || realtivePath.includes('/adhoc-history') || realtivePath.includes('/response-matrix') )) {
    return <Navigate to='/unauthorized-access' />;
  }
  return children
};

export default Middleware;