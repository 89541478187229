import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { React, useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import roleMapping from "../data/roleMapping";
import {
  LdsHeader,
  LdsSticky,
  LdsButton,
  LdsSelect,
  LdsLinkList,
  LdsLoadingSpinner,
  LdsLink,
  LdsIcon,
  // LdsImage,
} from "@elilillyco/ux-lds-react";
import { SideNav } from "../../src/layout/sideNav";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../assets/layout/layout.scss";
import Maintanance from "../error/Maintainance";
import { fetchOmtMt } from "../store/maintenance/omtMt";
import Loading from "../components/shared/Loading";
// import { callMsGraph } from '../../src/graph';
// import roleMapping from '../data/roleMapping';
// import axios from 'axios';
// import { demo } from '../api/index';
// import { loginRequest } from '../authConfig';

const Layout = (props) => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const location = useLocation();
  // console.log(location.pathname);
  const { user, loader } = useSelector(({ user }) => user);
  // const { instance, accounts } = useMsal();
  const [roles, setroles] = useState([
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "3",
      label: "3",
    },
    {
      value: "4",
      label: "4",
    },
    {
      value: "5",
      label: "5",
    },
    {
      value: "6",
      label: "6",
    },
  ]);
  // const [selectedrole, setselectedrole] = useState('');
  useEffect(() => {
    // console.log("hi layout");
    // console.log(props?.name?.data?.data?.role);
    const rolesdef = user?.data?.data?.role.map((option) => ({
      value: option,
      label: roleMapping[option],
    }));
    setroles(rolesdef);
    // setroles([
    //   {
    //     value: '1',
    //     label: '1',
    //   },
    //   {
    //     value: '2',
    //     label: '2',
    //   },
    //   {
    //     value: '3',
    //     label: '3',
    //   },
    //   {
    //     value: '4',
    //     label: '4',
    //   },
    // ]);
    // setselectedrole('1');
    // console.log(rolesdef);
    // console.log(roles);
  }, []);
  // useEffect(() => {
  //   instance
  //   .acquireTokenSilent({
  //       ...loginRequest,
  //       account: accounts[0],
  //   })
  //   .then((response) => {
  //       console.log("hi", response.accessToken);
  //       axios.defaults.headers.common['Authorization'] = `Bearer ${response.accessToken}`;
  //       axios.defaults.baseURL = 'https://k966saote2.execute-api.us-east-2.amazonaws.com/dev/';
  //       demo().then((resp) => {
  //         console.log('data');
  //         console.log(resp.data.data.role);
  //         const rolesdef = resp.data.data.role.map((option) => ({
  //           label: roleMapping[option],
  //           value: option,
  //         }));
  //         console.log(rolesdef);
  //         // setroles(rolesdef);
  //       })
  //   });

  // },[]);
  // const op = typeof(accounts) === 'undefined' ? 'pls login' : accounts[0].name;

  // // Silently acquires an access token which is then attached to a request for MS Graph data
  //        instance
  //             .acquireTokenSilent({
  //                 ...loginRequest,
  //                 account: accounts[0],
  //             })
  //             .then((response) => {
  //                 console.log("hi", response);
  //                 // callMsGraph(response.accessToken);
  //             });
  const logout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
    //   instance.logoutPopup({
    //     postLogoutRedirectUri: "/",
    //     mainWindowRedirectUri: "/"
    // });
  };
  const omtAd = sessionStorage.getItem("role");
  function selectedRole(selectedRoleValue) {
    if (location.pathname === "/") {
      navigate("/");
      return;
    }
    if (selectedRoleValue === "omms_admin") {
      navigate("/");
    } 
    // else if (selectedRoleValue === "omt_admin") {
    //   navigate("/config-omt");
    // }
    else if (selectedRoleValue.includes("omt_tacct")) {
      navigate("/tacct");
    } else if (
      selectedRoleValue === "omt_list_management_admin" ||
      selectedRoleValue === "omms_list_management"
    ) {
      navigate("/adhoc-List-Scrubbing");
    } else if (selectedRoleValue === "omt_response_matrix") {
      navigate("/response-matrix");
    } else if (selectedRoleValue !== "omt_admin") {
      navigate("/omms");
    }
  }

  function retunToMainPage() {
    window.location.href = "/users-list";
  }

  const navfn = () => {
    return (
      <>
        {!loader ? (
          <div>
            <LdsSticky className="layout-sticky">
              <LdsHeader
                childMobilePadding="10px"
                customLogo={
                  <a
                    href={
                      window.location.href.includes("tacct/") ? "/tacct" : "/"
                    }
                  >
                    <img src="/home.png" className="logo-image" />
                  </a>
                }
              >
                <div className="layout-container">
                  <div className="mt-2">
                    <p className="user-name">
                      {accounts[0]?.name?.split("-")[0].trim()}
                    </p>
                  </div>
                  <div>
                    {!loader && (
                      <LdsSelect
                        className="layout-select"
                        id="exampleSelect"
                        name="exampleSelect"
                        // onChange={(option) => setselectedrole(option.value)}
                        // value={roles[0].value}
                        options={user?.role?.map((option) => ({
                          value: option,
                          label: roleMapping[option],
                        }))}
                        value={sessionStorage.getItem("role")}
                        onChange={(option) => {
                          sessionStorage.setItem("role", option.value);
                          selectedRole(option.value);
                        }}
                      />
                    )}
                  </div>
                </div>
                {omtAd === "omms_admin" || omtAd === "omt_admin"? (
                  <div>
                    <LdsButton onClick={retunToMainPage}>Check Users</LdsButton>
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <LdsButton onClick={logout}>Logout</LdsButton>
                </div>
                {omtAd === "omt_admin" && (
                  <LdsIcon
                    className="settings"
                    onClick={() => navigate("/config-omt")}
                    name="GearSixFill"
                  />
                )}
              </LdsHeader>
            </LdsSticky>{" "}
            <Outlet />
          </div>
        ) : (
          <Loading/>
        )}{" "}
      </>
    );
  };
  // const navbarItems = [];
  if (location.pathname === "/blogs") {
    return (
      <>
        <SideNav className="side-nav" currentRoute={location.pathname}>
          {navfn()}
        </SideNav>
      </>
    );
  } else {
    return navfn();
  }
};

export default Layout;
