import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  rmCapFilters,
  getRmsTableData,
  getRmsFileDownload
} from "../../api/actions/responseMatrix";

const initialState = {
  brand: [],
  campaignId: [],
  department: [],
  indication: [],
  isRmsLoading: false,
  isLovsStatus: false,
  rmsTableList: [],
  isRmsTableLoading: true,
  pageCount: 1,
  tableApiStatus: false,
  selectedBrand: "",
};

function setRmslovs(state, action) {
  action.payload.data.data.campaign_id.sort();
  state.campaignId = action.payload.data.data.campaign_id.map((option) => ({
    value: option.toString(),
    label: option.toString(),
  }));
  state.campaignId.unshift({
    value: "",
    label: "Select",
  });
  state.department = [
    {
      value: "",
      label: "Select",
    },
    {
      value: "MEDICAL",
      label: "MEDICAL",
    },
    {
      value: "RETAIL",
      label: "RETAIL",
    },
  ];
  action.payload.data.data.indication.sort();
  state.indication = action.payload.data.data.indication.map((option) => ({
    value: option.toString(),
    label: option.toString(),
  }));
  state.indication.unshift({
    value: "",
    label: "Select",
  });
  const brand = action.payload.data.data.brand;
  if (brand.length === 1 && brand[0] === state.selectedBrand) {
    // state.product = state.product;
    return;
  } else {
    
  action.payload.data.data.brand.sort();
  state.brand = action.payload.data.data.brand.map((option) => ({
    value: option.toString(),
    label: option.toString(),
  }));
  state.brand.unshift({
    value: "",
    label: "Select",
  });
  }
}

const getRmsTableList = createAsyncThunk(
  "FETCH_RMS_TABLE_TADA",
  async (payload) => {
    const response = await getRmsTableData(payload);
    return response.data;
  }
);

const rmsLovsFilter = createAsyncThunk("FETCH_RMS_LOVS", async (payload) => {
  const response = await rmCapFilters(payload);
  return response;
});

const downloadRmsFile = createAsyncThunk(
  "rmsFileDownload",
  async (file_name) => {
    const response = await getRmsFileDownload(file_name);
    return response.data;
  }
);

const rmsStoreSlicer = createSlice({
  name: "RESPONSE MATRIX SUMMARY",
  initialState,
  reducers: {
    choosenBrandValue(state, action) {
      state.selectedBrand = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(rmsLovsFilter.pending, (state, action) => {
      state.brand.unshift({
        value: "",
        label: "Select",
      });
      let jsonObject = state.brand.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      state.brand = Array.from(uniqueSet).map(JSON.parse);
      state.campaignId.unshift({
        value: "",
        label: "Select",
      });
      state.department.unshift({
        value: "",
        label: "Select",
      });
      state.indication.unshift({
        value: "",
        label: "Select",
      });
      state.isRmsLoading = true;
      state.isLovsStatus = true;
    });
    builder.addCase(rmsLovsFilter.fulfilled, (state, action) => {
      const { brand, indication } = action.payload.data.data;
      if (brand.length === 0 && indication.length === 0) {
        state.isRmsLoading = false;
        state.isLovsStatus = false;
        return;
      }
      setRmslovs(state, action);
      state.isRmsLoading = false;
      state.isLovsStatus = false;
    });
    builder.addCase(rmsLovsFilter.rejected, (state, action) => {
      state.brand.unshift({
        value: "",
        label: "Select",
      });
      state.campaignId.unshift({
        value: "",
        label: "Select",
      });
      state.department.unshift({
        value: "",
        label: "Select",
      });
      state.indication.unshift({
        value: "",
        label: "Select",
      });
      state.isRmsLoading = true;
      state.isLovsStatus = false;
    });

    builder.addCase(getRmsTableList.pending, (state, action) => {
      state.tableApiStatus = true;
      state.isRmsTableLoading = false;
      state.isLovsStatus = true;
    });
    builder.addCase(getRmsTableList.fulfilled, (state, action) => {
      state.rmsTableList = action.payload.data;
      state.pageCount = action.payload.pages;
      state.tableApiStatus = true;
      state.isRmsTableLoading = true;
      state.isLovsStatus = false;
    });
    builder.addCase(getRmsTableList.rejected, (state, action) => {
      state.tableApiStatus = false;
      state.isRmsTableLoading = false;
      state.isLovsStatus = true;
    });
    builder.addCase(downloadRmsFile.pending, (state, action) => {});
    builder.addCase(downloadRmsFile.fulfilled, (state, action) => {
      state.isFileDataUrl = true;
    });
    builder.addCase(downloadRmsFile.rejected, (state, action) => {});
  },
});

export { getRmsTableList, rmsLovsFilter , downloadRmsFile };
export const { choosenBrandValue } = rmsStoreSlicer.actions;
export default rmsStoreSlicer.reducer;
