import { LdsButton } from "@elilillyco/ux-lds-react";
import "../../assets/utils/sortableTable.scss";
import { useState } from "react";

// mandatory : fields, headAlign, header, rowData, rowKeyName, sortedRowData
// headAlign : here we need to pass header Alignment css properties
// header : need to pass header names as a array
// rowData : need to pass rows data keys as an array to apply sorting.
// rowKeyName : need to pass the row data keys in a order of its display order
// ex: <tr> {entry.row_key1} </tr>
// <tr> {entry.row_key2} </tr>
// need to send row data keys. => [row_key1, row_key2]
// sortedRowData : it is a function from parent component, which contains a argument. it will be two way binding.

export default function LdsSortableTable({
  headAlign,
  header,
  rowData,
  rowKeyName,
  sortedRowData,
}) {
  const [isOpacity, setIsOpacity] = useState(null);
  const [isArrowClicked, SetIsArrowClicked] = useState(null);
  function DownArrowFunction(e, index) {
    const rowList = JSON.parse(JSON.stringify(rowData));
    let sortedData = [];
    const selectedID = e.target.id;
    const dataList = rowList.filter((arr, index) => {
      if (arr[selectedID] !== "None") {
        return arr[selectedID];
      }
    });
    if (dataList.length === 0) {
      return;
    }
    if (
      !isNaN(+dataList[0][selectedID]) ||
      !isNaN(+new Date(dataList[0][selectedID]).getTime())
    ) {
      if (!isNaN(+rowList[0][selectedID])) {
        sortedData = rowList.sort((a, b) => b[selectedID] - a[selectedID]);
      } else {
        sortedData = rowList.sort(
          (a, b) => new Date(b[selectedID]) - new Date(a[selectedID])
        );
      }
    } else {
      sortedData = rowList.sort((b, a) => {
        if (a[selectedID] < b[selectedID]) return -1;
        if (a[selectedID] > b[selectedID]) return 1;
        return 0;
      });
    }
    sortedRowData(sortedData);
  }

  function upArrowFunction(e, index) {
    const rowList = JSON.parse(JSON.stringify(rowData));
    let sortedData = [];
    const selectedID = e.target.id;
    const dataList = rowList.filter((arr, index) => {
      if (arr[selectedID] !== "None") {
        return arr[selectedID];
      }
    });
    if (dataList.length === 0) {
      return;
    }

    if (
      !isNaN(+dataList[0][selectedID]) ||
      !isNaN(+new Date(dataList[0][selectedID]).getTime())
    ) {
      if (!isNaN(+rowList[0][selectedID])) {
        sortedData = rowList.sort((b, a) => b[selectedID] - a[selectedID]);
      } else {
        sortedData = rowList.sort(
          (b, a) => new Date(b[selectedID]) - new Date(a[selectedID])
        );
      }
    } else {
      sortedData = rowList.sort((a, b) => {
        if (a[selectedID] < b[selectedID]) return -1;
        if (a[selectedID] > b[selectedID]) return 1;
        return 0;
      });
    }
    sortedRowData(sortedData);
  }

 //heading function
  function toggling(e, index) {
    let arrowClicked = true;
    if (isOpacity === index) {
      arrowClicked = !isArrowClicked;
    }
    SetIsArrowClicked(arrowClicked);

    setIsOpacity(index);
    if (arrowClicked) {
        upArrowFunction(e, index);
    } else {
        DownArrowFunction(e, index);
    }
  }

  return (
    <>
      <thead>
        <tr>
          {header.map((headingName, index) => {
            return (
              <th key={index} className={headAlign + " thead-alignment"}>
                <LdsButton
                  id={rowKeyName[index]}
                  value={rowKeyName[index]}
                  linkButton={true}
                  onClick={(e) => toggling(e, index)}
                  className="headingMargin d-flex align-center header-height"
                >
                  <div id={rowKeyName[index]} value={rowKeyName[index]}>
                    {headingName}
                  </div>
                  <div id={rowKeyName[index]} value={rowKeyName[index]}>
                    <div className="buttonCareUpFill">
                      <div
                        className={`up-caret ${
                          (isArrowClicked && isOpacity) === index
                            ? "opacity-button"
                            : "no-opacity-button"
                        }`}
                        id={rowKeyName[index]}
                        value={rowKeyName[index]}></div>
                    </div>
                    <div className="buttonCareDownFill">
                      <div
                        className={`down-caret ${
                          (!isArrowClicked && isOpacity) === index
                            ? "opacity-button"
                            : "no-opacity-button"
                        }`}
                        id={rowKeyName[index]}
                        value={rowKeyName[index]}></div>
                    </div>
                  </div>
                </LdsButton>
              </th>
            );
          })}
        </tr>
      </thead>
    </>
  );
}
