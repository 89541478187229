import { LdsButton, LdsCheckbox, LdsOptionSelector, LdsOptionSelectorItem, LdsRadio, LdsSwitch, LdsTile, LdsToast, useToastContext } from '@elilillyco/ux-lds-react'
import React, { useEffect, useState } from 'react'
import { fetchOmtMt } from '../store/maintenance/omtMt'
import { omtMaintenancePut } from '../store/maintenance/omtMtPut'
import { useDispatch, useSelector } from 'react-redux'
import "../assets/components/Maintainance.scss"
import infoToastConfig from '../data/utils/infoToastConfig.js'

export const ConfigOmt = () => {
  const omtMt = useSelector(({ omtMtSlice }) => omtMtSlice.MtState);
  const [isOn, setIsOn] = useState(false)
  const dispatch = useDispatch()
  const { addToast } = useToastContext();

  const underMt = (e,val,msg) => {
    const check = e.target.value==='on'?true:false
    const payload = { cnfg_val: check, cnfg_typ: val }
    check===true?
    (addToast(
      infoToastConfig({
        toastMessage: `${msg} is going under maintenance.`,
        variant: "informative",
      }))):
    (addToast(
      infoToastConfig({
        toastMessage: `${msg} is going live.`,
        variant: "informative",
      })))
    dispatch(omtMaintenancePut(payload)).then((e)=>e.payload.statusCode===200&&window.location.reload())
  

      }
  return (
    <>
      <div className='container mt-5 '>
        <h2>OMT Maintainance Page</h2>
      </div>
      {omtMt && <div className='container alignItems '>
        <LdsTile className='removeBG'>
          
        <div className=' options-mt'>
        <strong className='font fs-5'>OMMS Tool</strong>
        <div className='row'>
        {/* <LdsToast /> */}
        <LdsRadio
                id="on"
                label="On"
                name="omms"
                value = 'on'
                defaultChecked = {omtMt[0] && omtMt[0]?.cnfg_val === true?true:false}
                onChange = {(e)=>{underMt(e,'omms_maintenance','OMMS'); setIsOn(true)}}
                error
              />
              <LdsRadio
                id="off"
                label="Off"
                name="omms"
                value='off'
                defaultChecked={omtMt[0] && omtMt[0]?.cnfg_val === false ?true:false}
                onChange = {(e)=>{underMt(e,'omms_maintenance','OMMS'); setIsOn(true)}}
                error
              /></div></div> 
         <div className=' options-mt mt-3'>
        <strong className='font fs-5'>TACCT Tool</strong>
        <div className='row'>
        <LdsRadio
                id="on"
                label="On"
                name="tacct"
                value = 'on'
                defaultChecked = {omtMt[1] && omtMt[1]?.cnfg_val === true?true:false}
                onChange = {(e)=>{underMt(e,'tacct_maintenance','TACCT'); setIsOn(true)}}
                error
              />
              <LdsRadio
                id="off"
                label="Off"
                name="tacct"
                value='off'
                defaultChecked={omtMt[1] && omtMt[1]?.cnfg_val === false ?true:false}
                onChange = {(e)=>{underMt(e,'tacct_maintenance','TACCT'); setIsOn(true)}}
                error
              /></div></div> 
        </LdsTile>
      </div>}
    </>

  )
}
