import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { users } from '../api/index';

export const initialState = {
  user:{},
  loader: false,
  defaultRole: sessionStorage.getItem('role'),
  AllRole: [],
  userUUID: undefined,
  role_ids: [],
  first_name: '',
  last_name: ''
};

const fetchUsers = createAsyncThunk('user/fetchUsers', async () => {
  const response = await users();
  return response.data.data;
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.user = action.payload;
      
      if (action.payload.role.includes('omms_admin')){
        state.defaultRole = 'omms_admin';
      }
      else if (action.payload.role.includes('omms_vendor')){
        state.defaultRole = 'omms_vendor';
      }
      else if (action.payload.role.includes('omms_peer_review_bia')){
        state.defaultRole = 'omms_peer_review_bia';
      }
      else if (action.payload.role.includes('omms_peer_review_channel')){
        state.defaultRole = 'omms_peer_review_channel';
      }
      else if (action.payload.role.includes('omms_peer_review_campaign')){
        state.defaultRole = 'omms_peer_review_campaign';
      }
      else if (action.payload.role.includes('omms_metadata_manager')){
        state.defaultRole = 'omms_metadata_manager';
      }
      else if (action.payload.role.includes('omms_read_only')){
        state.defaultRole = 'omms_read_only';
      }
      else {
        state.defaultRole = action.payload.role[0];
      }
      // sessionStorage.setItem('role', action.payload.role[0]);
      state.userUUID = action.payload.uuid;
      state.AllRole = action.payload.role;
      state.loader = false;
      state.role_ids = action.payload.role_ids;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
    });
    builder.addCase(fetchUsers.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.user = action.payload;
      state.loader = false;
    });
  },
});

export { fetchUsers };

export default userSlice.reducer;