import { LdsLink, LdsModal, LdsButton, useLdsModal, LdsTextField, LdsToast, useToastContext, LdsPagination, LdsIcon, LdsTable, LdsLoadingSpinner } from '@elilillyco/ux-lds-react';
import HeaderNav from '../../HeaderNav';
import "../../../assets/components/TACCT/BL/editCategory.scss"
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryData } from "../../../store/Components/TACCT/BL/taccteditcategory";
import { updateCategoryData } from '../../../store/Components/TACCT/BL/taccteditcategoryput';
import { useState } from 'react';

export default function EditCategory() {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCategoryData({
            cat_def: '',
            cat: '',
            pageSize: 10,
            pageNumber: 1,
            order_by: 'NAME',
            order_direction: 'ASC'
        }));
    }, [dispatch],
    );

    const data = useSelector(({ tacctEditCatSlice }) => tacctEditCatSlice.getCategories);
    const loading = useSelector(({ tacctEditCatSlice }) => tacctEditCatSlice.loading);
    const tPages = useSelector(({ tacctEditCatSlice }) => tacctEditCatSlice.totalPages);
    const role = useSelector(({ user }) => user.role_ids);
    const totalPages = tPages;
    const [pageRangeVisible] = useState(5);
    var [categoryID, setCategoryId] = useState(0);
    var [editedCat, setEditedCat] = useState('');
    var [editedCatOpDef, setEditedCatOpDef] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    var [catDefSearch, setCatDefSearch] = useState('');
    var [catSearch, setCatSearch] = useState('');
    const [lastEvent, setLastEvent] = useState('');
    const { isModalOpen, setIsModalOpen } = useLdsModal();
    const { addToast } = useToastContext();
    const [orderBy, setOrderBy] = useState('NAME');
    const [orderDirection, setOrderDirection] = useState('ASC')
    const toastConfig = (errTxtMsg, msgType) => {
        return {
          toastMessage: errTxtMsg,
          actionText: "",
          actionCallback: () => { },
          variant: msgType,
          position: "top",
          align: "center",
          dismissible: true,
          light: false,
          timeout: 5000,
          inline: false,
          autoDismiss: true,
        }
      };
    const clickLink = (e, value) => {
        setLastEvent(`clickLink, value: ${value}`);
        dispatch(fetchCategoryData({
            cat_def: catDefSearch,
            cat: catSearch,
            pageSize: 10,
            pageNumber: value,
            order_by:orderBy,
            order_direction: orderDirection
        }));
    };
    const clickNext = (e, value) => {
        setLastEvent(`clickNext, value: ${value}`);
        dispatch(fetchCategoryData({
            cat_def: catDefSearch,
            cat: catSearch,
            pageSize: 10,
            pageNumber: currentPage + 1,
            order_by:orderBy,
            order_direction: orderDirection
        }));
    };
    const clickPrev = (e, value) => {
        setLastEvent(`clickPrev, value: ${value}`);
        dispatch(fetchCategoryData({
            cat_def: catDefSearch,
            cat: catSearch,
            pageSize: 10,
            pageNumber: currentPage - 1,
            order_by:orderBy,
            order_direction: orderDirection
        }));
    };

    function searchFld() {
        const currPage = 1
        setCurrentPage(currPage);
        dispatch(fetchCategoryData({
            cat_def: catDefSearch,
            cat: catSearch,
            pageSize: 10,
            pageNumber: currPage,
            order_by:orderBy,
            order_direction: orderDirection
        }));
    }

    function clearSearch() {
        setCatSearch('')
        setCatDefSearch('')
        dispatch(fetchCategoryData({
            cat_def: '',
            cat: '',
            pageSize: 10,
            pageNumber: 1,
            order_by:orderBy,
            order_direction: orderDirection
        }));
    }

    function showModal(categoryId) {
        setCategoryId(categoryId);
        const categoryAtt = data.find((item) => item.blue_list_metadata_id === categoryId);
        setEditedCat(categoryAtt.name)
        setEditedCatOpDef(categoryAtt.definition);
        setIsModalOpen(true)
    }
    const regex = /^[a-zA-Z0-9-_,.' ]*$/;
    async function updateCategory() {
        // Prepare the payload
        setEditedCat(editedCat.trim())
        setEditedCatOpDef(editedCatOpDef.trim());
        if (editedCat === '' || editedCatOpDef === '') {
            return addToast(toastConfig("Please add both fields", "warning"))
        }
        if(!regex.test(editedCat)){
            return addToast(toastConfig("No special characters except '-' and '_'", "error"))
        }
        else{
        const payload = {
            edit_cat_values: {
                blue_list_metadata_id: categoryID,
                name: editedCat,
                definition: editedCatOpDef,
                updated_by: await getCurrentUserId()
            },
        };
        dispatch(updateCategoryData(payload));
        setIsModalOpen(false);
        clearSearch();
    }
    }

    async function getCurrentUserId() {
        const currentRole = sessionStorage.getItem('role');
        const currentUserData = role.filter((roleData) => Object.keys(roleData)[0] === currentRole);
        return currentUserData[0][currentRole];
    }

    function orderbyname() {
        setCurrentPage(1)
        setOrderBy('NAME')
        const cPage = 1
        const direction = orderDirection === 'ASC' ? 'DESC' : 'ASC';
        setOrderDirection(direction)
        dispatch(fetchCategoryData({
            cat_def: catDefSearch,
            cat: catSearch,
            pageSize: 10,
            pageNumber: cPage,
            order_by: 'NAME',
            order_direction: direction
        }));
    }

    function orderbydef() {
        setCurrentPage(1)
        setOrderBy('DEFINITION')
        const direction = orderDirection === 'ASC' ? 'DESC' : 'ASC';
        const  currentP = 1
        setOrderDirection(direction)
        dispatch(fetchCategoryData({
            cat_def: catDefSearch,
            cat: catSearch,
            pageSize: 10,
            pageNumber: currentP,
            order_by: 'DEFINITION',
            order_direction: direction
        }));
    }

    return (
        <>
            <div>
                <div>
                    <HeaderNav msg="Blue List Content Category & Subcategory" />
                </div>
                <div class="overvieweditcat">
                    <LdsLink href="/tacct/blue-list">
                        <LdsIcon
                            description="CaretLeft"
                            label="search"
                            name="CaretLeft"
                            inline
                        />
                        Back to Overview
                    </LdsLink>
                    <div class="heading">
                        <h2>Edit Category</h2>
                    </div>
                </div>
                <div class="search">
                    <LdsTextField
                        id='Category'
                        name='Category'
                        label='Category'
                        value={catSearch}
                        onChange={(e) => setCatSearch(e.target.value)}
                    />
                    <LdsTextField
                        id='CatOp'
                        name='CatOp'
                        label='Category Operational Definition'
                        value={catDefSearch}
                        onChange={(e) => setCatDefSearch(e.target.value)}
                    />
                    <div className="buttonGroup">
                        <LdsButton className="searchbtn"
                            onClick={searchFld}
                        >
                            Search
                        </LdsButton>
                        <LdsButton
                            onClick={clearSearch}
                        >
                            Clear All
                        </LdsButton>


                    </div>
                </div>
            </div>
            {loading &&
                <div className='spinner container'>
                    <LdsLoadingSpinner size={90} />
                </div>
            }
            <div class="tableDiv catTable">
                {!loading &&

                    <LdsTable sortable
                        id="my-table"
                        class="catTable"

                    >
                        <thead>
                            <tr>
                                <th style={{ width: '40%' }} scope="col">
                                    <div className="attriDiv3">
                                        Category
                                        <div class="iconStyle">
                                            <LdsIcon
                                                description="Ascending-Descending"
                                                label="Ascending-Descending"
                                                name="ArrowsDownUp"
                                                inline
                                                onClick={orderbyname}
                                            />
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div className="attriDiv3 alignment">
                                        Category Operational Definition
                                        <div class="iconStyle">
                                            <LdsIcon
                                                description="Ascending-Descending"
                                                label="Ascending-Descending"
                                                name="ArrowsDownUp"
                                                inline
                                                onClick={orderbydef}
                                            />
                                        </div>
                                    </div></th>
                                <th style={{ width: '20%' }} scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((obj) => {
                                return (
                                    <tr
                                    >
                                        <td>
                                            {obj.name}
                                        </td>
                                        <td>
                                            {obj.definition}
                                        </td>

                                        <td className=''>
                                            <div
                                                className="row-style centerI"
                                                onClick={() => showModal(obj.blue_list_metadata_id)}
                                            >
                                                <LdsIcon className="table-icons" label="Edit" name='PencilSimpleLineFill' />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </LdsTable>
                }
            </div>
            {!loading &&
                <LdsPagination
                    onLinkClick={clickLink}
                    onNextClick={clickNext}
                    onPrevClick={clickPrev}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    pageRangeVisible={pageRangeVisible}
                />
            }
            <LdsModal
                modalId="testModal2"
                open={isModalOpen}
                setModalOpen={setIsModalOpen}
                heading="Edit Category"
                persistent
            >


                <LdsToast />
                <form
                    id="add-new-entry-by-vendor-form my-form"
                    class="align"
                >

                    <div align="center">
                        <LdsTextField
                            id="category"
                            name="category"
                            type="text"
                            value={editedCat}
                            onChange={(e) => setEditedCat(e.target.value)}

                            label='Category'
                            className="editCatInput"
                            rules="optional:true|pattern:^[a-zA-Z0-9\s_-]+$"
                        >
                        </LdsTextField>
                        <LdsTextField
                            id="CategoryOpDef"
                            name="CategoryOpDef"
                            type="text"
                            className="editCatInput"
                            value={editedCatOpDef}
                            onChange={(e) => setEditedCatOpDef(e.target.value)}

                            label='Category Operational Definition'
                            rules="optional:true|pattern:^[a-zA-Z0-9\s_-]+$"
                        >
                        </LdsTextField>
                    </div>
                    <div className='btnEditCat'>
                        <LdsButton className='btnCancel' onClick={() => setIsModalOpen(false)} >Cancel</LdsButton>
                        <LdsButton onClick={updateCategory} >Save</LdsButton>
                    </div>
                </form>
            </LdsModal>
        </>
    )
}
