import ctx from "../plugins/axios";

export async function putUsersList(payload) {
    try {
      const data = await ctx.put(`/userlist`, payload);
      return data;
    } catch (error) {
      return error;
    }
  };
