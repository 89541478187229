import React, { useEffect, useState } from 'react'
import HeaderNav from '../components/HeaderNav'
import { LdsButton, LdsCard, LdsCheckbox, LdsLoadingSpinner, LdsSearch, LdsSelect, LdsTable, LdsTextField, useToastContext } from '@elilillyco/ux-lds-react'
import { useDispatch, useSelector } from 'react-redux'
import { PutUsersList, UsersList } from '../store/Components/OMMS/UsersList.js'
import infoToastConfig from '../data/utils/infoToastConfig.js'

const UsersListPage = () => {
    const dispatch = useDispatch();
    const roleData = useSelector(({ getUsersLists }) => getUsersLists.role);
    const [column, setColumn] = useState()
    const [values, setValues] = useState()
    const [getVal, setVal] = useState("")
    const [loader, setLoader] = useState()
    const [tableLoader, setTableLoader] = useState()
    const [design, setDesign] = useState(<></>)
    const [selectedRole, setSelectedRole] = useState('all')
    const [getId, setId] = useState([]);
    const { addToast } = useToastContext();
    const role2 = useSelector(({ user }) => user.role_ids);

    const userFlag = (status) =>{
        const stat = status===true?'True':'False'
        const currentRole = sessionStorage.getItem('role');
        const currentUserData = role2.filter((roleData) => Object.keys(roleData)[0] === currentRole);
        const updated_by = currentUserData[0][currentRole];
        const payload = {
            id:getId,
            status:stat,
            updated_by:updated_by
        }
        dispatch(PutUsersList(payload)).then((res)=> (res?.payload?.statusCode===200 ?
        (setId([]), dispatch(UsersList()).then((data) => (setTableLoader(false),setColumn(Object?.keys(data?.payload?.data?.[0]).slice(0, 4)), setValues(data?.payload?.data))),addToast(infoToastConfig({
            toastMessage: `Status has been updated.`,
            variant: "informative",
          }))):(addToast(infoToastConfig({
    toastMessage: `Status update failed.!`,
    variant: "error",
  })))))
    }
    useEffect(() => {
        dispatch(UsersList())
            .then((data) => (setLoader(data ? true : false), setColumn(Object?.keys(data?.payload?.data?.[0]).slice(0, 4)), setValues(data?.payload?.data)))
    }, [])


    useEffect(() => {
        setDesign(<>  <LdsSelect
            style={{ width: "30%" }}
            className='mb-4'
            errorMessage="This field is invalid"
            id="mySelect"
            label="Select an Role"
            name="mySelect"
            onChange={(e) => setSelectedRole(e?.value)}
            options={roleData?.map((role => (
                {
                    label: role,
                    value: role
                }
            )))}
        /> </>)

    }, [roleData])

    const filteredRole = values && (selectedRole?.toLowerCase() === "all" ? values : values.filter(data =>
        data.role.toLowerCase().includes(selectedRole?.toLowerCase())
    ));

    const filteredValues = filteredRole && filteredRole?.filter(data =>
        data.name.toLowerCase().includes(getVal?.toLocaleLowerCase())
    );
   

    const checkedData = (e) => {
        if (e.target.checked) {
          if (!getId.includes(parseInt(e.target.value))) {
            setId([...getId, parseInt(e.target.value)])
          }
        }
        if (!e.target.checked) {
          if (getId.includes(parseInt(e.target.value))) {
            setId(getId.filter((obj) => obj !== parseInt(e.target.value)))
          }
        }
      }

    const trueActiveFlag = filteredValues && filteredValues.filter(data => data.active_flag).length
    return (
        <>
            <div className='mb-2'>
                <HeaderNav msg="OMMS - Users List " />
            </div>
            {loader ? <LdsCard>
                <LdsCard.Body>
                    <div style={{width:'70%'}}>
                        <div className='col-16'>
                            <div className='d-flex justify-content-evenly align-items-center'>
                                <LdsTextField
                                    placeholder="Search Users by Name"
                                    value={getVal}
                                    onChange={(e) => setVal(e.target.value.trimStart())}
                                />

                                {design}

                                <div>
                                    <strong>Total {selectedRole.replace(/_/g, ' ').toUpperCase()} Users Count: {filteredValues?.length}</strong>
                                    <br />
                                    <h5>Active: {trueActiveFlag} </h5>
                                    <h5>InActive: {filteredValues?.length - trueActiveFlag} </h5>
                                </div>
                            </div>
                {/* <div className='d-flex justify-content-center mt-2 mb-2'>
                <LdsButton onClick={()=>{userFlag(true); setTableLoader(true)}}>Mark Active</LdsButton> &nbsp;&nbsp;&nbsp;     <LdsButton classes='outlined' onClick={()=>{userFlag(false);setTableLoader(true)}}>Mark Inactive</LdsButton>
                </div> */}
                            {tableLoader ? <div className='spinner container '>
                <LdsLoadingSpinner size={70} />
            </div>:<LdsTable>
                                <thead>
                                    
                                    <tr>
                                        {/* <th></th> */}
                                        {column?.map((data, index) =>
                                            <th scope="col" className='ta-left' >{data.replace(/_/g, ' ').toUpperCase()}</th>
                                        )}
                                    </tr>
                                </thead>

                                <tbody>
                                   
                                    {filteredValues?.map((data, rowIndex) =>
                                        <tr key={rowIndex}>
                                            {/* <td><LdsCheckbox
                                                name="opt1"
                                                value={data.id}
                                                onChange={(e) => { checkedData(e) }}
                                                checked={getId.includes(parseInt(data.id))}
                                                /></td> */}
                                            {Object.values(data).slice(0, 4).map((val, colIndex) =>
                                                <td role="row" className='ta-left'>
                                                    {typeof val === 'boolean' ? (val ? 'Active' : 'InActive') : val.toLowerCase()}
                                                </td>
                                            )}

                                        </tr>
                                    )}
                                </tbody>
                            </LdsTable>}
                                {filteredValues?.length < 1 ? <h3 className='mt-5 container '>No data found </h3> : ""
                                }
                        </div>

                    </div>
                </LdsCard.Body>
            </LdsCard> : <div className='spinner container '>
                <LdsLoadingSpinner size={90} />
            </div>
            }
        </>
    )
}

export default UsersListPage