import React, { useState, useEffect } from 'react'
import HeaderNav from '../../../components/HeaderNav';
import { LdsButton, LdsCheckbox, LdsIcon, LdsLoadingSpinner,LdsToast, LdsModal, LdsTable,LdsLink, useLdsModal, useToastContext } from '@elilillyco/ux-lds-react'
import { useSelector, useDispatch } from 'react-redux';
import { UPDATE_TN_APPROVAL_DATA } from '../../../store/Components/TACCT/TN/tnapproval';
import { fetchTnData } from '../../../store/Components/TACCT/TN/tnOverview'
import { useNavigate } from "react-router-dom";



export const TonalityApproval = () => {
  const data = useSelector(({  tnDataSlice }) =>  tnDataSlice.tnBody);
  const navigate = useNavigate();
  const loader = useSelector(({  tnDataSlice }) =>  tnDataSlice.loading);
  const [editorRole, setEditorRole]= useState('')
  const [editedStatus, setEditedStatus] = useState('')
  const role = useSelector(({ user }) => user.role_ids);
  const { isModalOpen: isApproveModalOpen, setIsModalOpen: setApprovedIsModalOpen } = useLdsModal();
  const { isModalOpen: isRejectModalOpen, setIsModalOpen: setRejectIsModalOpen } = useLdsModal();
  const { addToast } = useToastContext();
  const [selectAll, setSelectAll] = useState(false)
 
const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTnData('rfa'))
  }, [])

const toggleSelectAll = () => {
    const allRows = selectAll ? [] : data;
    setID(allRows)
}

const [getID, setID] = useState([])
useEffect(()=>{
  setSelectAll(getID?.length === data?.length && data?.length > 0)
},[getID, data])

const getSelectedData = (e, data, index) => {
  const isChecked = e.target.checked;
  let updatedSlectedRows;

  if (selectAll) {
    updatedSlectedRows = isChecked ? [...getID, data] : getID.filter(row => row !== data);
  }
  else {
    updatedSlectedRows = isChecked ? [...getID, data] : getID.filter(row => row !== data)
  }
  setID(updatedSlectedRows)
}

const selectedTonalityForm = getID?.map(row => row.tonality_overview_detail_id)


  
  function handleClick(){
    setApprovedIsModalOpen(false)
    setRejectIsModalOpen(false)
  }
  function rejectHandleClick(){
    setRejectIsModalOpen(false)
    setApprovedIsModalOpen(false)
  }

  function modalOpen(){
    if (selectedTonalityForm.length === 0){
      addToast({
      toastMessage: "Please select atleast one record ",
      actionText: "",
      // actionCallback: () => console.log("View clicked"),
      variant: "warning",
      position: "top",
      align: "center",
      dismissible: true,
      light: false,
      timeout: 2000,
      inline: false,
      autoDismiss: true,})
      return;
    }
    setApprovedIsModalOpen(true)
  }

  function rejectmodalOpen(){
    if (selectedTonalityForm.length === 0){
      addToast({
      toastMessage: "Please select atleast one record ",
      actionText: "",
      // actionCallback: () => console.log("View clicked"),
      variant: "warning",
      position: "top",
      align: "center",
      dismissible: true,
      light: false,
      timeout: 2000,
      inline: false,
      autoDismiss: true,})
      return;
    }
    setRejectIsModalOpen(true)
  }

  async function getCurrentUserId() {
    const currentRole = sessionStorage.getItem('role');
    const currentUserData = role.filter((roleData) => Object.keys(roleData)[0] === currentRole);
    return currentUserData[0][currentRole];
  }

  async function approveTactic(){
    
     const payload = {
      requestData: {
        role: editorRole,
        status: editedStatus,
        tonality_id: selectedTonalityForm,
        user_id: await getCurrentUserId()
      }}
      dispatch(UPDATE_TN_APPROVAL_DATA(payload));
      navigate({
        pathname: '/tacct/tonality-ops',
      });
  };
  async function rejectTactic(){
    const payload = {
     requestData: {
       role: editorRole,
       status: editedStatus,
       tonality_id: selectedTonalityForm,
       user_id: await getCurrentUserId()
     }}
     dispatch(UPDATE_TN_APPROVAL_DATA(payload));
     navigate({
      pathname: '/tacct/tonality-ops',
    });
 };

  function checkCCBRole() {
    const role = sessionStorage.getItem('role');
    if (role === "omt_tacct_admin"|| role === "omt_tacct_bl_approver_taxccb"|| role === "omt_admin") {
      return true;
    }
    return false;
  }

  function checkOmniRole() {
    const role = sessionStorage.getItem('role');
    if (role === "omt_tacct_admin"|| role === "omt_tacct_bl_approver_omni"|| role === "omt_admin") {
      return true;
    }
    return false;
  }

  return (
    <>
     <HeaderNav msg={"Tonality"} />
        <div className="overview-container-bu">
          <div className="button-container-bu">
          <LdsLink href="/tacct/tonality-ops">
          <LdsIcon
          description="CaretLeft"
          label="search"
          name="CaretLeft"
          inline
          />Back to Overview </LdsLink>
          </div>
          </div>
          <div className='m-5'>
          <LdsTable className='bu-table'>
          <thead className='table-headers-bu'>
            <tr>
              <th>
                <span className='ml-2'>Select All</span><br />
                <LdsCheckbox name="opt1" onChange={toggleSelectAll} checked={selectAll} />
              </th>            
              <th >TaxonomyCCB</th>
              <th >Omni Channel Managers</th>
              <th >Status</th>
              <th >Tonality Values 
               {/* <LdsIcon
              description="ArrowsDownUp"
              label="ArrowsDownUp"
              name="ArrowsDownUp"
              inline
              onClick={()=>{handleSort('business_unit')}}
              />  */}
              </th>
              <th >Tonality Operational Definition
               {/* <LdsIcon
                description="ArrowsDownUp"
                label="ArrowsDownUp"
                name="ArrowsDownUp"
                inline
                onClick={()=>{handleSort('business_unit_abbreviation')}}
                />  */}
                </th>
                </tr>
          </thead>
          {!loader ? (data && data.length > 0 ? (
          <tbody className='table-body-bu'>
          {data.map((data, rowIndex) => (
              <tr
              role="row" key={rowIndex}>
                 <td className='center'>
                  <LdsCheckbox name="opt1" onChange={(e) => { getSelectedData(e, data, rowIndex) }} checked={getID?.includes(data)} />
                </td>
                
                <td>{data.taxonomy_ccb_status}</td>
                <td>{data.omnichannel_managers_status}</td>
                <td>{data.status}</td>
                <td>{data.tonality_values}</td>
                <td>{data.tonality_operational_definition}</td>
              </tr>
            ))}
                </tbody>
            ) : (<tbody>
                <div>
                <span className='no-data'>No Data Available</span>
                </div>
                </tbody>
              )
            ) : (
              <LdsLoadingSpinner class='loader' size={90} animationSpeed={500} />
            )}
        </LdsTable>
        </div>
        <div className='approve-sec-bu'>
        {checkCCBRole() &&
        <div className='approve-bu'>
          <h4>Taxonomy CCB</h4>
          <div className='approve-buttons-bu'>
            <LdsIcon className='approve-icon-bu' onClick = {()=>{modalOpen();setEditedStatus('APPROVED'); setEditorRole('omt_tacct_bl_approver_taxccb')}} name='CheckCircle' label='Approve'/>
            <LdsToast/>
            <LdsModal
            modalId="testModal"
            open={isApproveModalOpen}
            setModalOpen={setApprovedIsModalOpen}
            heading="Are you sure you want to approve these values?"
          >           
            <div className = "space-bu">
            <LdsButton  onClick = {()=>{handleClick(); approveTactic()}}>Yes</LdsButton>&nbsp;
            <LdsButton  onClick = {()=>{handleClick()}}>No</LdsButton>
            </div>
            <LdsToast/>
          </LdsModal>

            <LdsIcon className='approve-icon-bu' onClick = {()=>{ rejectmodalOpen();setEditedStatus('REJECTED'); setEditorRole('omt_tacct_bl_approver_taxccb')}} name="XCircle"
            label="Reject"/>        
            <LdsModal 
            modalId="testModal"
            open={isRejectModalOpen}
            setModalOpen={setRejectIsModalOpen}
            heading="Are you sure you want to reject these values?"
          >         
            <div className = "space-bu col">
            <LdsButton onClick = {()=>{rejectHandleClick(); rejectTactic()}}>Yes</LdsButton>&nbsp;
            <LdsButton onClick={()=>rejectHandleClick()}>No</LdsButton>
            </div>

          </LdsModal>
          
          </div>
        </div>}
        {checkOmniRole() &&
        <div className='approve-bu'>
          <h4>OmniChannel Managers</h4>
          <div className='approve-buttons-bu'>
            <LdsIcon className='approve-icon-bu' onClick={() => {modalOpen();setEditedStatus('APPROVED');setEditorRole("omt_tacct_bl_approver_omni")}} name='CheckCircle' label='Approve'/>
            <LdsToast/>
            <LdsModal
            modalId="testModal"
            open={isApproveModalOpen}
            setModalOpen={setApprovedIsModalOpen}
            heading="Are you sure you want to approve these values?"
          >
            
            <div className = "space-bu">
            <LdsButton  onClick = {()=>{rejectHandleClick(); approveTactic()}}>Yes</LdsButton>&nbsp;
            <LdsButton  onClick = {()=>{rejectHandleClick()}}>No</LdsButton>
            </div>

          </LdsModal>

            <LdsIcon className='approve-icon-bu' onClick = {()=>{ rejectmodalOpen();setEditedStatus('REJECTED'); setEditorRole("omt_tacct_bl_approver_omni")}} name="XCircle"
            label="Reject"/>
            <LdsToast/>
            <LdsModal
            modalId="testModal"
            open={isRejectModalOpen}
            setModalOpen={setRejectIsModalOpen}
            heading="Are you sure you want to reject these values?"
          >          
            <div className = "space-bu">
            <LdsButton  onClick = {()=>{ rejectHandleClick(); rejectTactic()}}>Yes</LdsButton>&nbsp;
            <LdsButton  onClick = {()=>{ rejectHandleClick()}}>No</LdsButton>
            </div>

          </LdsModal>
          </div>
        </div>}
      </div>
    </>
  )
}
