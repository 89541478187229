import React, { useState, useEffect } from 'react'
import { LdsButton, LdsCheckbox, LdsIcon, LdsLoadingSpinner, LdsToast, LdsModal, LdsTable, LdsTile, LdsLink, useLdsModal, useToastContext, LdsTextarea } from '@elilillyco/ux-lds-react'
import { useSelector, useDispatch } from 'react-redux';
import '../../../../src/assets/components/TACCT/GF/gfRequestForApproval.scss'
import { fetchGfData } from '../../../store/Components/TACCT/GF/tacctGfOverview';
import HeaderNav from '../../../components/HeaderNav';
import { UPDATE_GFAPPROVAL_DATA } from '../../../store/Components/TACCT/GF/gfApproval';

export const GFApproval = () => {
  //role
  const role = useSelector(({ user }) => user.role_ids);
  //toast
  const { addToast } = useToastContext();
  //for modal states
  const { isModalOpen: isApproveModalOpen, setIsModalOpen: setApprovedIsModalOpen } = useLdsModal();
  const { isModalOpen: isRejectModalOpen, setIsModalOpen: setRejectIsModalOpen } = useLdsModal();
  //holds api response 
  const [data, setData] = useState();

  const [editedStatus, setEditedStatus] = useState('')
  const [editedStatusforModal, setEditedStatusforModal] = useState('')

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchGfData('rfa')).then((data) => setData(data?.payload?.data?.filter(obj => obj.status === "PENDING")))
  }, [])

  function checkOmniRole() {
    const role = sessionStorage.getItem('role');
    if (role === "omt_tacct_admin" || role === "omt_tacct_bl_approver_omni"|| role === "omt_admin") {
      return true;
    }
    return false;
  }

  function checkCCBRole() {
    const role = sessionStorage.getItem('role');
    if (role === "omt_tacct_admin" || role === "omt_tacct_bl_approver_taxccb"|| role === "omt_admin") {
      return true;
    }
    return false;
  }

  const [getID, setID] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  useEffect(()=>{
    setSelectAll(getID?.length === data?.length && data?.length > 0)
  },[getID, data])

  const toggleSelectAll = () => {
    const allRows = selectAll ? [] : data;
    setID(allRows)
  }

  const getSelectedData = (e, data, index) => {
    const isChecked = e.target.checked;
    let updatedSlectedRows;

    if (selectAll) {
      updatedSlectedRows = isChecked ? [...getID, data] : getID.filter(row => row !== data);
    }
    else {
      updatedSlectedRows = isChecked ? [...getID, data] : getID.filter(row => row !== data)
    }
    setID(updatedSlectedRows)
  }
  const selectedGrammeticalForm = getID?.map(row => row.grammatical_form_overview_detail_id)

  const [editorRole, setEditorRole] = useState('')
  const currentRole = sessionStorage.getItem('role');
  const currentUserData = role.filter((roleData) => Object.keys(roleData)[0] === currentRole);

  const handleClick = (type, fromRole) => {
    if (getID.length === 0) {
      addToast({
        toastMessage: "Please select atleast one record ",
        actionText: "",
        variant: "warning",
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: 2000,
        inline: false,
        autoDismiss: true,
      })
      return;
    }

    else {
      if (type === "approve") {
        if (fromRole === "ccb") {
          setEditedStatusforModal("ccb")
          setApprovedIsModalOpen(true)
          setEditedStatus('APPROVED')
          setEditorRole('omt_tacct_bl_approver_taxccb')
        } else {
          setEditedStatusforModal("omni")
          setApprovedIsModalOpen(true)
          setEditorRole('omt_tacct_bl_approver_omni')
          setEditedStatus('APPROVED')
        }
      }
      else if ((type === "reject")) {
        if (fromRole === "ccb") {
          setEditedStatusforModal("ccb")
          setRejectIsModalOpen(true)
          setEditedStatus('REJECTED');
          setEditorRole('omt_tacct_bl_approver_taxccb')
          // approveTactic()
        }
        else {
          setEditedStatusforModal("omni")
          setRejectIsModalOpen(true)
          setEditorRole('omt_tacct_bl_approver_omni')
          setEditedStatus('REJECTED');
          // approveTactic
        }
      }
    }
  }

  const approveTactic = () => {
    const payload = {
      requestData: {
        role: editorRole,
        status: editedStatus,
        grammatical_form_overview_id: selectedGrammeticalForm,
        user_id: currentUserData[0][currentRole]
      }
    }
    dispatch(UPDATE_GFAPPROVAL_DATA(payload))
    setTimeout(()=>{
      window.location.href = '/tacct/grammatical-form';
    },1000)
  };

  return (
    <>
      <HeaderNav msg={"Grammatical Form"} />
      <div className='mt-2'>
        <LdsLink href="/tacct/grammatical-form">
          <LdsIcon
            description="CaretLeft"
            label="search"
            name="CaretLeft"
            inline
          />
          Back to Overview
        </LdsLink>
      </div>

      <div className='m-5'>
        <LdsTable className='bu-table'>
          <thead className='table-headers-bu'>
            <tr>
              <th>
                <span className='ml-2'>Select All</span><br />
                <LdsCheckbox name="opt1" onChange={toggleSelectAll} checked={selectAll} />
              </th>
              <th >TaxonomyCCB</th>
              <th >Omni Channel Managers</th>
              <th >Status</th>
              <th >Grammatical Form
                {/* for shorting enable if required  */}
                {/* <LdsIcon
              description="ArrowsDownUp"
              label="ArrowsDownUp"
              name="ArrowsDownUp"
              inline
              onClick={()=>{handleSort('business_unit')}}
              />  */}
              </th>
              <th >Grammatical Form Operational Definition
                {/* for shorting enable if required  */}
                {/* <LdsIcon
                description="ArrowsDownUp"
                label="ArrowsDownUp"
                name="ArrowsDownUp"
                inline
                onClick={()=>{handleSort('business_unit_abbriviation')}}
                />  */}
              </th>
            </tr>
          </thead>
          {data ? (data && data.length > 0 ? <tbody className='table-body-bu'>
            {data.map((data, rowIndex) => (
              <tr
                role="row" key={rowIndex}>
                <td className='center'>
                  <LdsCheckbox name="opt1" onChange={(e) => { getSelectedData(e, data, rowIndex) }} checked={getID?.includes(data)} />
                </td>
                <td >{data.taxonomy_ccb_status}</td>
                <td>{data.omnichannel_managers_status}</td>
                <td>{data.status}</td>
                <td>{data.grammatical_form}</td>
                <td>{data.grammatical_form_operational_definition}</td>
              </tr>
            ))}
          </tbody> : (<tbody>
            <div>
              <span className='no-data'>No Data Available</span>
            </div>
          </tbody>
          )
          ) : <LdsLoadingSpinner class='loader' size={90} animationSpeed={500} />}
        </LdsTable>
      </div>

      <div className='approve-sec-bu'>
        {checkCCBRole() &&
          <div className='approve-bu'>
            <h4>Taxonomy CCB</h4>
            <div className='approve-buttons-bu'>
              <LdsIcon className='approve-icon-bu' onClick={() => handleClick("approve", "ccb")} name='CheckCircle' label='Approve' />
              <LdsToast />
              {editedStatusforModal === "ccb" ? <LdsModal
                modalId="testModal"
                open={isApproveModalOpen}
                setModalOpen={setApprovedIsModalOpen}
                heading="Are you sure you want to approve these values?"
              >
                <div className="space-bu">
                  <LdsButton onClick={() => (approveTactic())}>Yes</LdsButton>&nbsp;
                  <LdsButton onClick={() => setApprovedIsModalOpen(false)}>No</LdsButton>
                </div>
                <LdsToast />
              </LdsModal> : ""
              }

              <LdsIcon className='approve-icon-bu' onClick={() => handleClick("reject", "ccb")} name="XCircle"
                label="Reject" />
              <LdsToast />
              {editedStatusforModal === "ccb" ? <LdsModal
                modalId="testModal"
                open={isRejectModalOpen}
                setModalOpen={setRejectIsModalOpen}
                heading="Are you sure you want to reject these values?"
              >
                <div className="space-bu">
                  <LdsButton onClick={() => { approveTactic() }}>Yes</LdsButton>&nbsp;
                  <LdsButton onClick={() => { setRejectIsModalOpen(false) }}>No</LdsButton>
                </div>
              </LdsModal> : ""}

            </div>
          </div>}
        {checkOmniRole() &&
          <div className='approve-bu'>
            <h4>OmniChannel Managers</h4>
            <div className='approve-buttons-bu'>
              <LdsIcon className='approve-icon-bu' onClick={() => handleClick("approve", "omni")} name='CheckCircle' label='Approve' />
              <LdsToast />
              {editedStatusforModal === "omni" ? <LdsModal
                modalId="testModal"
                open={isApproveModalOpen}
                setModalOpen={setApprovedIsModalOpen}
                heading="Are you sure you want to approve these values?"
              >
                <div className="space-bu">
                  <LdsButton onClick={() => { approveTactic() }}>Yes</LdsButton>&nbsp;
                  <LdsButton onClick={() => setApprovedIsModalOpen(false)}>No</LdsButton>
                </div>
                <LdsToast />
              </LdsModal> : ""}

              <LdsIcon className='approve-icon-bu' onClick={() => handleClick("reject", "omni")} name="XCircle"
                label="Reject" />
              <LdsToast />
              {editedStatusforModal === "omni" ? <LdsModal
                modalId="testModal"
                open={isRejectModalOpen}
                setModalOpen={setRejectIsModalOpen}
                heading="Are you sure you want to reject these values?"
              >
                <div className="space-bu">
                  <LdsButton onClick={() => { approveTactic() }}>yes</LdsButton>&nbsp;
                  <LdsButton onClick={() => { setRejectIsModalOpen(false) }}>No</LdsButton>
                </div>

              </LdsModal> : ""}
            </div>
          </div>
        }
      </div>
    </>
  )
}
