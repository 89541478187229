import ctx from '../../../plugins/axios';

export default async (queryparams) => {
  try {
    const { data, status } = await ctx.get(`/archiveentries/export?deliverySource=${queryparams.deliverySource}&suppName=${queryparams.suppName}&delSrcType=${queryparams.delSrcType}&del_channel=${queryparams.del_channel}&platform=${queryparams.platform}&contactType=${queryparams.contactType}&status=${queryparams.status}&engClass=${queryparams.engClass}&metLabel=${queryparams.metLabel}&metdef=${queryparams.metdef}&hierClass=${queryparams.hierClass}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};

