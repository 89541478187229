import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getExceptionReport from '../../../api/actions/omms/getExceptionReport';
import editExceptionReport from '../../../api/actions/omms/editExceptionReport';


export const initialState = {
  data: [],
  totalPages: 0,
  loader: false
}

const fetchException = createAsyncThunk(
  "/er-overview",
  async (queryParams) => {
    const response = await getExceptionReport(queryParams);
    return await response.data;
  }
);

const editException = createAsyncThunk(
  "/edit-exception-reporting",
  async (queryParams) => {
    const response = await editExceptionReport(queryParams);
    return await response.data;
  }
);

const exceptionSlice = createSlice({
  name: 'exception',
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchException.fulfilled, (state, action) => {
     
      // let temp = JSON.parse(JSON.stringify(action.payload.data));
      // state.vendorData = temp.map((obj) => {
      //   obj['check'] = false;
      //   // obj['toggleCheck'] = false;
      //   return obj
      // });
      state.totalPages = action.payload.numberOfPages;

      state.loader = false;
    });
    builder.addCase(fetchException.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(fetchException.rejected, (state, action) => {
      state.user = action.payload;
      state.loader = false;
    });
    builder.addCase(editException.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(editException.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(editException.rejected, (state, action) => {
      state.loader = false;
    });
  },
});

export { fetchException, editException };
export default exceptionSlice.reducer;