import ctx from "../plugins/axios";

export async function getOmtMt () {
    try {
      const data = await ctx.get('/bl-metadata');
      return data;
    } catch (error) {
      return error;
    }
  };
